<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8 govde">
          <h3 :class="{ 'text-right':isArbc }">{{ hadis.Konu }}</h3>
          <p v-html="hadis.Metin" :class="{ 'text-right':isArbc }"></p>
          <br>
        </div>
        <div class="col-md-2"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "hadis",
  data() {
    return {
      hadis: [],
      //paramsı burdan aliyoruz
      dil: this.$route.params.dil,
      kitapIsmi: this.$route.params.kitap,
      konuNo: this.$route.params.konuno,
      hadisNo: this.$route.params.hadisno,
      isArbc:null
    }
  },
  components: {
  },
  created() {
    axios.get("https://hadis.kabikavseyn.com/api/" + this.dil + "/" + this.kitapIsmi + "/" + this.konuNo + "/" + this.hadisNo)
        .then(respons => {
          this.hadis = respons.data
          if (this.dil ==="Arb"){
            this.isArbc=true
          }
        })
  }
}
</script>

<style scoped>
.govde {
  justify-content: center;
  align-content: center;
  margin-top: 5%;
  margin-bottom: 5%;
  font-size: 20px;
}

</style>