<template>
  <div class="card mb-3" style="max-width: 540px;">
    <div class="row g-0">
      <div class="col-md-4">
        <img
            :src=img_src
            :alt=kitap.isim
            class="img-fluid"
        />
      </div>
      <div class="col-md-8">
        <div class="card-body ">
          <h5 class="card-title d-flex justify-content-center">{{ kitap.Kitap }}</h5>
          <div class="d-grid gap-2 d-md-flex justify-content-md-end ml-3 mt-5">
            <router-link class="btn btn-primary" tag="button" :to=kitap.Slug>
              Kitap İçeriği
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "kitaplar",
  data() {
    return {
      img_src: "/img/kitap.jpeg",
    }
  },
  props: ["kitap"],
  methods: {}
}
</script>

<style scoped>
.card {
  margin-top: 5%;
}

</style>