<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-primary">
    <router-link class="nav-item" tag="a" to="/">
      <a class="navbar-brand baslik">KAB-I KAVSEYN-HADİS</a>
    </router-link>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <!--   searchbar solda kalmasi icin bunun burda kalmasi gerekiyor-->
      </ul>
      <form class="form-inline my-2 my-lg-0" method="get">
        <input v-model="aranan" class="form-control mr-sm-2" name="q" type="search" placeholder="Arama"  @keypress.enter.prevent="arama()" aria-label="Search">
        <button type="button" class="btn btn-success" @click="arama()">Ara</button>
      </form>
    </div>
  </nav>
</template>
<script>
import { router } from "../../router";

export default {
  data() {
    return {
      aranan: null
    }
  },
  methods: {
    arama() {
      console.log(this.aranan)
      router.push("/arama/aranan/1?q=" + this.aranan)
      this.aranan=''
    },
  },
}
</script>
<style scoped>
.baslik {
  color: aliceblue !important;
}
</style>