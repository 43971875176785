<template>
  <footer class="fixed-bottom bg-light text-center text-lg-start">
    <!-- Copyright -->
    <div class="text-center p-3">
      © 2021 Copyright : HayTeknoloji
    </div>
    <!-- Copyright -->
  </footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style >

</style>