<template>
  <div class="container mb-5">
    <div class="row">
      <div class="col-md-6">
        <kitaplar
            v-for="(kitap, index) in TrKitaplar"
            :kitap="kitap"
            :key="index"
        />
      </div>
      <div class="col-md-6">
        <kitaplar
            v-for="(kitap, index) in ArbKitaplar"
            :kitap="kitap"
            :key="index"
        />
      </div>
    </div>
  </div>
</template>

<script>
import kitaplar from "./kitap";
import axios from "axios"

export default {
  name: "kitaplik",

  data() {
    return {
      TrKitaplar: [],
      ArbKitaplar: []
    }
  },
  components: {
    kitaplar,
  },
  created() {
    axios.get("https://hadis.kabikavseyn.com/api/")
        .then(response => {
          response.data.forEach(item => {
            if (item.Dil === "Tr") {
              this.TrKitaplar.push(item)
            } else {
              this.ArbKitaplar.push(item)
            }
          })
        })
  }

}
</script>

<style scoped>
div.row {
  display: flex;
  justify-content: space-between;
}
</style>