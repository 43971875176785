<template>
  <div>
    <Header />
    <router-view></router-view>
    <Footer/>
  </div>
</template>
<script>
import Header from "./components/shared/Header";
import Footer from "./components/shared/Footer"
export default {
  components: {
    Header,
    Footer,
  },
}
</script>
<style>
* {
  font-family: "Times New Roman";
}
</style>