<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-2" @click="bosalt()"></div>
        <div class="col-md-6 govde">
          <ul>
            <a v-for="(hadis,index) in hadisler" :key="index" class="faaruk" :class="{ 'acik': tiklandi===index , 'text-right':isArbc}" @click="konuGettir(hadis, index)" >
              <p :id="'hadis'+index"  v-html="hadis.Konu"></p>
              <br>
            </a>
          </ul>
        </div>
        <div class="col-md-4 icerik " v-if="acik" :class="{'text-right arb':isArbc}">
          <a @click="bosalt" class="close"/>
          <loading v-if="yuklendi"/>
          <ul class="mt-3">
            <router-link tag="a" :to=Metin.Slug v-for="(Metin, index) in konular"  :key="index">
              <p v-html="Metin.Konu"></p>
            </router-link>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import loading from "../shared/loading";

export default {
  name: "fihrist",
  data() {
    return {
      konular: [],
      hadisler: [],
      dil: this.$route.params.dil,
      kitapIsmi: this.$route.params.kitap,
      tiklandi: null,
      acik:false,
      yuklendi: true,
      isArbc:false
    }
  },
  components: {
    loading,
  },

  created() {
    axios.get("https://hadis.kabikavseyn.com/api/" + this.dil + "/" + this.kitapIsmi)
        .then(respons => {
          this.hadisler = respons.data
          if (this.$route.params.dil === "Arb"){
            this.isArbc=true
          }
        })
  },
  methods: {
    konuGettir(hadis, i) {
      this.acik = true
      this.tiklandi=i
      this.yuklendi = true
      this.konular = null
      axios.get("https://hadis.kabikavseyn.com/api/" + this.dil + "/" + this.kitapIsmi + "/" + hadis.KonuNumarasi)
          .then(respons => {
            this.yuklendi = false
            this.konular = respons.data
          })
    },
    bosalt() {
      this.konular = null
      this.acik = false
      this.tiklandi=null
    },

  }

}
</script>

<style scoped>
.arb{
  font-size: 20px;
}
.row {
  margin-top: 5%;
  margin-bottom: 5%;
}
.faaruk > p {
  text-align: center;
  cursor: pointer;
  color:black;
  opacity: 0.5;
  font-size: 20px;
}
.acik > p {
  opacity: 1;
  color: blue;
}


.icerik {
  margin-top: -1%;
  border-radius: 15px;
  box-shadow: 12px 12px 22px grey;
}

.close {
  position: absolute;
  right: 15px;
  top: 7px;
  width: 16px;
  height: 16px;
  opacity: 0.3;
}

.close:hover {
  color: red;
}

.close:before, .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 15px;
  width: 2px;
  background-color: red;
}

.close:before {
  transform: rotate(45deg);
}

.close:after {
  transform: rotate(-45deg);
}
</style>