<template>
  <div>
    <div class="container mb-5">
      <div class="row">
        <col-md-12>
          <nav aria-label="">
            <ul class="pagination pagination-sm">
              <router-link :class="{ 'active' : sayfa==index+1}" v-for="(page,index) in paginations" tag="li"
                           :to=slugGetir(index+1) class="page-item" :key="index">
                <a class="page-link">{{ page }}</a>
              </router-link>
            </ul>
          </nav>
        </col-md-12>
      </div>
    </div>
  </div>
</template>

<script>

//import axios from "axios";

export default {
  name: "pagination",
  data() {
    return {}
  },
  props: ["paginations", "sayfa", "aranan",],
  created() {

  },
  methods: {
    slugGetir(index) {
      return "/arama/aranan/" + index + "?q=" + this.aranan
    },
  },
  watch: {}
}
</script>

<style scoped>
.row {
  justify-content: center;
  align-content: center;
  margin-bottom: 5%;
}
</style>