import Vue from "vue";
import VueRouter from "vue-router";
import kitaplik from "./components/pages/kitaplik";
import fihrist from "./components/pages/fihrist";
import hadis from "./components/pages/hadis";
import sayfa_bulunmadi from "./components/pages/NotFound";
import arananlarsayfasi from "./components/pages/arananlarsayfasi";

Vue.use(VueRouter)

export const router = new VueRouter({
    base: '/',
    mode: 'history',
    routes: [
        {
            path: '',
            name: 'kitaplik',
            component: kitaplik
        },
        {
            path: '/:dil/:kitap',
            name: 'fihrist',
            component: fihrist
        },
        {
            path: '/arama/aranan/:aranan',
            name: 'arananlar',
            component: arananlarsayfasi
        },
        {
            path: '/:dil/:kitap/:konuno',
            name: 'fihrist',
            component: fihrist
        },
        {
            path: '/:dil/:kitap/:konuno/:hadisno',
            name: 'hadis',
            component: hadis
        },
        {
            path: "*",
            name: "sayfa_bulunamadi",
            component: sayfa_bulunmadi
        }
    ],

})


