<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10">
          <div v-if="yuklendMi" class="yukleniyor">
            <loading/>
          </div>
          <table class="table" v-if="!yuklendMi">
            <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Kitap</th>
              <th scope="col">Konu</th>
              <th scope="col">Metin</th>
            </tr>
            </thead>
            <router-link tag="thead" v-for="(hadis,index) in hadisler" :to=hadis.Slug :key="index">

              <tr>
                <th scope="row">{{ (index + 1) + (30 * (sayfa - 1)) }}</th>
                <td>{{ hadis.Kitap }}</td>
                <td>{{ hadis.Konu }}</td>
                <td v-html="hadis.Metin" style="color: #3498db"></td>
              </tr>
            </router-link>
          </table>
          <div v-if="isVeriNull" class="d-flex justify-content-center" style="color: red; font-size: 30px" >
            <p><b>{{arananKelime}}</b> ile ilgili hadis bulunamadi</p>
          </div>
        </div>
        <div class="col-md-1"></div>
      </div>
      <pagination :model="sayfa"
                  :paginations="paginations"
                  :aranan="this.$route.query.q"
                  :sayfa="this.$route.params.aranan"
                  :hadisler="hadisler"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import pagination from "../shared/pagination";
import loading from "../shared/loading";

export default {
  name: "arananlarsayfasi",
  data() {
    return {
      hadisler: null,
      paginations: null,
      isVeriNull: false,
      arananKelime:this.$route.query.q,
      sayfa: this.$route.params.aranan,
      yuklendMi: true
    }
  },
  emits: ['hadisler'],
  async mounted() {
    console.log("ccc")
    await this.getir()
    console.log("ddd")
  },
  methods: {
    async getir() {
      console.log("aaaaaaa")
    this.yuklendMi = true
      axios.get("https://hadis.kabikavseyn.com/api/arama/aranan/" + this.$route.params.aranan + "?q=" + this.$route.query.q)
          .then(respons => {
            this.isVeriNull=false
            this.hadisler = respons.data.ArananSayfasiWM
            this.paginations = respons.data.Pegination
            this.sayfa = this.$route.params.aranan
            this.yuklendMi = false
            if (respons.data.ArananSayfasiWM === null) {
              this.isVeriNull= true
            }
          })
      console.log("bbbb")
    },
  },
  components: {
    pagination,
    loading,
  },
  watch: {
    async '$route'(n, o) {
      if (o) {
        await this.getir()
      }
    },
  }
}
</script>

<style scoped>
th, td {
  text-align: center;
  justify-content: center;
  cursor: pointer;
}

.yukleniyor {

  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  align-content: center !important;
  left: 50% !important;
  height: 100vh;
  display: block;
}

</style>